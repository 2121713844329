import React from "react";
import { SanityImage } from "@components/sanity/sanityImage";
import { ModuleWrapper } from "../moduleWrapper";

const TwoImageBlock = ({ config, title, subtitles, image1, image2 }) => {
  return (
    <ModuleWrapper {...config}>
      <div className="hidden  px-gutter space-x-10 ">
        <div className="h-[600px] w-auto  overflow-hidden">
          <SanityImage image={image1} className=" w-full h-full" />
        </div>
        <div className=" overflow-hidden  h-[600px] w-auto">
          <SanityImage image={image2} className=" w-full h-full" />
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default TwoImageBlock;
